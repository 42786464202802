body {
	/*background-color: #333;*/
	/*background-color: #7094db;*/
	/*background-color: #303036;*/
	background-color: #2b363b;
	color: #fff;
}

.blue-background {
	background-color: #2892d7;
}

.white-background {
	background-color: #fef9ff;
}

.navbar {
	border-bottom: #008ed6 3px solid;
	opacity: 0.8;
}

#self {
	width: 90%;
	height: 90%;
}

#col2,
#u {
	border: 2px solid red;
}

.js-image {
	border: 2px solid blue;
}

.btb {
	border-bottom: #008ed6 3px solid;
	border-top: #008ed6 3px solid;

	opacity: 0.8;
}

#twitter {
	text-decoration: none;
	color: white;
}

#linkedin {
	color: white;
}

#github {
	text-decoration: none;
	color: white;
}

#image-container {
	width: 1000%;
	height: 100000000%;
}

#card {
	height: 50%;
	width: 50%;
}

form {
	border: #008ed6 3px solid;
	padding: 5%;
	background-color: #6d7f88;
}
